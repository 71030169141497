import $ from 'jquery'

window.jQuery = $
window.$ = $

require('normalize.css/normalize.css')
require('slick-carousel/slick/slick.scss')
require('aos/dist/aos.css')
require('./styles/index.scss')

import slick from 'slick-carousel/slick/slick'
import validate from 'jquery-validation'
import AOS from 'aos'

$(document).ready(function() {

  // Init fadeIn for better exp on user loading
  $('body').fadeIn('slow')

  // Init animations for block of the page
  AOS.init()

  //Init slick slider of the hero
  $('.slider').slick({
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000
  })

  var openLink = (url) => {
    window.open(url);
  }

  const loadMap = function(){
    if($('#map').hasClass('mapboxgl-map')) return $('#map').css('display', 'block');
    const sw = new mapboxgl.LngLat(-74.02375489353375, 40.59095470123876);
    const ne = new mapboxgl.LngLat(-73.96548836389574,  40.56918039645487);
    const llb = new mapboxgl.LngLatBounds(sw, ne);

    mapboxgl.accessToken = 'pk.eyJ1IjoiY2FsbGllMTciLCJhIjoiWE9PN0w1WSJ9.6uiyq7nvSCFzNQkWfLTnfw';

    var map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/callie17/cjyiuvu480y7w1cmllcy4wu28',
      bounds: llb,
    });

    map.scrollZoom.disable();
    map.on('click', function(e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: ['OD Icon', '1_2_OceanDrive', 'BrightonBeach', 'SeaGate', 'ConeyIslandBeach', 'MTA Label', 'Manhattan', 'Ferry Icon', 'Kaiser Park', 'Ferry Text', 'Verrazzano Bridge']
      });

      if (!features.length) return;
      var feature = features[0];

      if (feature.layer.id === 'OD Icon' || feature.layer.id === '1_2_OceanDrive'){
        openLink("https://maps.app.goo.gl/a32qZLmtuVqbFHgr6");
      }
      if (feature.layer.id === 'BrightonBeach'){
        openLink("https://maps.app.goo.gl/K6tzGhbW1nbPLp537");
      }
      if (feature.layer.id === 'SeaGate'){
        openLink("https://maps.app.goo.gl/zbYTDk6hHEf9xZkv7");
      }
      if (feature.layer.id === 'ConeyIslandBeach'){
        openLink("https://g.page/coney-island-beach-and-boardwalk?share");
      }
      if (feature.layer.id === 'MTA Label'){
        openLink("https://maps.app.goo.gl/1quvkpoTc2GZTwXK6");
      }
      if (feature.layer.id === 'Ferry Icon' || feature.layer.id === 'Ferry Text'){
        openLink("https://maps.app.goo.gl/oAfLTitSFVZNdtu2A");
      }

      if (feature.layer.id === 'Kaiser Park'){
        openLink("https://g.page/kaiser-park?share");
      }
      if (feature.layer.id === 'Verrazzano Bridge'){
        openLink("https://goo.gl/maps/BEn87txA12oCziPy5");
      }
    });
  }

  $('.go-to-next-block').click(function(ev) {
    $('html, body').animate({
      scrollTop: $($(ev.currentTarget).attr('href')).offset().top - 100,
    }, 1500)
  })

  let form = $('#contact-form')

  form.find('input').keyup(() => {
    $('p.invalid').fadeOut('fast')
    form.find('[type=submit]').prop('disabled', false)
  })

  form.validate({
    rules: {
      name: 'required',
      email: {
        required: true,
        email: true,
      },
      phone: {
        number: true,
        min: 1,
      },
      budget: {
        number: true,
        min: 1,
      },
    },
    messages: {
      name: 'Please specify your name.',
      email: {
        required: 'We need your email address to contact you.',
        email: 'Your email address must be in the format of name@domain.com.',
      },
      phone: {
        number: 'Please enter a valid number.',
      },
      budget: {
        number: 'Please enter a valid budget.',
      },
    },
    submitHandler: function() {
      let data = {
        "formId":"58835e1c-a972-49e1-9a97-3c057cae810d",
        "subject": "Ocean Drive Teaser Inquiry",
        "fields": {
          "Name": form.find('#name').val(),
          "Email": form.find('#email').val(),
          "Phone": form.find('#phone').val(),
          "Preferred unit size": form.find('#unit').val(),
          "Monthly rental budget": form.find('#budget').val(),
          "Contact Point": form.find('#contact-point').val(),
        }
      }

      form.find('[type=submit]').prop('disabled', true)
      form.find('[type=submit]').text('Please wait...')

      if (window.dataLayer) {
        window.dataLayer.push({'event': 'SubmitContact'});
      }

      $.ajax({
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        url: 'https://static-form-email-delivery.sidewaysdigital.com/sendEmail',
        data: JSON.stringify(data),
        processData: false,
        complete: function (xhr) {
          if (xhr.status == 201) {
            form.find('[type=submit]').hide()
            form.find('.success').fadeIn('fast')
          } else {
            form.find('[type=submit]').hide()
            form.find('.error').fadeIn('fast')
          }
        }
      })
    },
    invalidHandler: function() {
      $('.invalid').fadeIn('fast')
      form.find('[type=submit]').prop('disabled', true)
    },
    errorClass: 'invalid',
  })


  const init = function(){
    if($(window).width() >= 1020 ){
      loadMap()
    }else{
      $('#map').css('display', 'none');
    }
  }

  $(window).resize(function(){
    init();
  });

  init();
})
